<script>
import { warehouseService } from "../../../helpers/backend/warehouse.service";
// import maps from "../component/maps.vue";
export default {
  components: {
    // maps,
  },
  data() {
    return {
      form: {
        id: null,
        name: "",
        location: "",
        coordinate: {
          lat: -6.191435,
          lng: 106.844124,
        },
        region_id: null
      },
      region_options: [{ text: "Select One", value: null }],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created(){
    this.fetchRegion()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      warehouseService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Warehouse. Error : " + error;
          this.busy = false;
        }
      )
    },
    setPosition(position) {
      this.form.coordinate = position
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.name = "";
      this.form.location = "";
      this.form.region_id = null
      this.form.coordinate = {
        lat: -6.191435,
        lng: 106.844124,
      };
      // this.$refs.mapsRef.setPosition(
      //   this.form.coordinate.lat,
      //   this.form.coordinate.lng
      // );
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.name.$el.focus()
        this.busy = true
        warehouseService.getById(id).then(
          (data) => {
            if (data.warehouse != null) {
              this.form.id = data.warehouse.id;
              this.form.name = data.warehouse.name;
              this.form.location = data.warehouse.location;
              this.form.coordinate = data.warehouse.coordinate;
              // this.$refs.mapsRef.setPosition(
              //   this.form.coordinate.lat,
              //   this.form.coordinate.lng
              // );
              this.form.region_id = data.warehouse.region_id
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get warehouse type to update. Error : warehouse not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error =
              "Failed to get warehouse type to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    fetchRegion(){
      warehouseService.getAllRegion().then(
          (data) => {
            if (data.regions != null) {
              this.region_options = this.region_options.concat(
                data.regions.map(function (mType) {
                  return { text: mType.name, value: mType.id };
                })
              );
            } else {
              this.showDismissibleAlert = true;
              this.error =
                "Failed to get region. Error : region not found";
            }
            this.busy = false;
          },
          (err) => {
            this.showDismissibleAlert = true;
            this.error =
              "Failed to get region. Error : " + err;
            this.busy = false;
          }
        );
    },
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Warehouse Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Warehouse Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Location :"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.location"
                  type="text"
                  placeholder="Location"
                  required
                ></b-form-input>
              </b-form-group>

              <!-- <maps
                :coordinate="this.form.coordinate"
                ref="mapsRef"
                @changePosition="setPosition"
              /> -->

              <b-form-group
                id="input-group-3"
                label="Regions: "
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="form.region_id"
                  :options="region_options"
                  required
                ></b-form-select>
              </b-form-group>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



